<template>
  <v-dialog v-model="dialog" width="700" @click:outside="handleOutside">
    <v-form lazy-validation ref="form" v-model="valid">
      <v-card @mousedown="drag">
        <DialogTitle :title="$vuetify.lang.t('$vuetify.expenseLog')" />

        <v-card-text class="pb-2">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  row
                  :rules="rules"
                  v-model="radioGroup"
                  :label="this.$vuetify.lang.t('$vuetify.selecttype')"
                  class="pt-0 mt-0 mn-2"
                >
                  <v-radio
                    :label="this.$vuetify.lang.t('$vuetify.income')"
                    value="1"
                  />
                  <v-radio
                    :label="this.$vuetify.lang.t('$vuetify.expense')"
                    value="2"
                  />
                </v-radio-group>
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-autocomplete
                  :rules="rules"
                  :label="this.$vuetify.lang.t('$vuetify.fenLei')"
                  :items="category"
                  :item-text="(item) => item.name"
                  v-model="obj.type_name"
                  outlined
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-select
                  :rules="rules"
                  :label="this.$vuetify.lang.t('$vuetify.currency')"
                  :items="currencyItems"
                  :item-text="(item) => item.code"
                  :item-value="(item) => item.code"
                  v-model="obj.currency"
                  outlined
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-text-field
                  :rules="rules"
                  type="number"
                  :label="amountLabel"
                  v-model="obj.money"
                  outlined
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="rules"
                      outlined
                      v-model="date"
                      :label="dateLabel"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="4" class="py-0">
                <v-text-field
                  :label="this.$vuetify.lang.t('$vuetify.company')"
                  outlined
                  v-model="obj.company"
                />
              </v-col>

              <v-col cols="12" lg="8" md="8" sm="8" class="py-0">
                <v-text-field
                  :label="this.$vuetify.lang.t('$vuetify.comAddress')"
                  outlined
                  v-model="obj.address"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-text-field
                  :rules="rules"
                  :label="this.$vuetify.lang.t('$vuetify.contactDetails')"
                  outlined
                  v-model="obj.contact"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-text-field
                  :rules="rules"
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.contactIphone')"
                  v-model="obj.phone"
                  type="number"
                />
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" class="py-0">
                <v-text-field
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.contactEmail')"
                  v-model="obj.email"
                  type="email"
                />
              </v-col>

              <v-col cols="12" lg="8" md="8" sm="6" class="py-0">
                <v-text-field
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.otherContact')"
                  v-model="obj.other_contact"
                />
              </v-col>

              <v-col cols="12" class="py-0">
                <v-text-field
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.bankName')"
                  v-model="obj.bank_name"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-text-field
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.bankAccNo')"
                  v-model="obj.account_no"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6" class="py-0">
                <v-text-field
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.bankAcc')"
                  v-model="obj.bank_account"
                />
              </v-col>

              <v-col cols="12" class="pt-2 pb-0">
                <v-textarea
                  outlined
                  :label="this.$vuetify.lang.t('$vuetify.note')"
                  v-model="obj.remark"
                />
              </v-col>

              <v-col class="pb-0" cols="12">
                <v-file-input
                  label="Attchment"
                  outlined
                  show-size
                  counter
                  multiple
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="files"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>

                <span class="display-1 font-weight-bold">
                  List of Documents
                </span>

                <v-sheet
                  width="100%"
                  elevation="4"
                  class="py-4"
                  :height="obj.attach.length < 1 ? '200px' : ''"
                >
                  <span
                    class="d-flex align-center justify-center"
                    v-for="(item, key) in obj.attach"
                    :key="key"
                  >
                    <v-list-item
                      class="tile ml-4 mr-2 my-2"
                      :href="dataUrl + item.md5"
                      target="_blank"
                    >
                      {{ item.filename }}
                    </v-list-item>

                    <v-btn
                      color="red"
                      class="ml-2 mr-4"
                      @click="removeAttach(item)"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </span>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-8 pt-4">
          <v-spacer />

          <v-btn @click="handleCancel" color="red" outlined>
            {{ this.$vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn @click="validate" color="primary">
            {{ this.$vuetify.lang.t("$vuetify.sub") }}
          </v-btn>

          <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { listCurrency } from "@/api/system";
import { draggable } from "@/api/customFunction";
import serverConfig from "@/utils/serverConfig";

export default {
  name: "ExpenseCRUD",
  props: { dialog: Boolean, obj: Object, category: Array },
  components: {
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  data() {
    return {
      readOnly: !this.permissionCheck("modify"),
      valid: false,
      menu: false,
      currencyItems: [],
      rules: [(v) => !!v || "this field is required"],
      files: undefined,
      dataUrl: serverConfig.img_url,
    };
  },
  methods: {
    handleCancel() {
      this.resetValidation();
      this.$emit("update:dialog", false);
      this.files = undefined;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("editEvent", this.obj, this.files);
        this.files = undefined;
        this.resetValidation();
      } else {
        console.log("false");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    removeAttach(item) {
      this.obj.attach.splice(this.obj.attach.indexOf(item), 1);
    },
    handleOutside() {
      this.$emit("update:dialog", false);

      this.resetValidation();
    },
    drag() {
      draggable();
    },
  },
  mounted() {
    listCurrency()
      .then((res) => {
        this.currencyItems = [...res.items];
      })
      .catch((err) => console.log("Error", err));
  },
  computed: {
    dateLabel() {
      return this.radioGroup == "1"
        ? this.$vuetify.lang.t("$vuetify.dateReceipt")
        : this.$vuetify.lang.t("$vuetify.paymentDate");
    },
    amountLabel() {
      return this.radioGroup == "1"
        ? this.$vuetify.lang.t("$vuetify.amountReceived")
        : this.$vuetify.lang.t("$vuetify.paymentAmount");
    },
    date: {
      get: function () {
        return this.obj.pay_time;
      },
      set: function (value) {
        const temp = new Date().toISOString().substring(0, 10);
        this.obj.pay_time = value;
      },
    },
    radioGroup: {
      get: function () {
        return this.obj.in_out == 2 ? "2" : "1";
      },
      set: function (value) {
        this.obj.in_out = value;
      },
    },
  },
};
</script>

<style>
</style>